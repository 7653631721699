import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject, forkJoin } from 'rxjs';
import { timer } from 'rxjs/internal/observable/timer';
import { OrderService } from './order.service';
import { map, take } from 'rxjs/operators';
import { BrowserNotificationService } from '../shared/services';
import { SoundService } from '.';

@Injectable({
  providedIn: 'root'
})
export class OrderViewingService {
  MINUTE_IN_MILLISECONDS = 1000 * 60;
  orders$ = new BehaviorSubject<any[]>([]);
  scheduledOrders$ = new BehaviorSubject<any[]>([]);
  timer$: Subscription | undefined = undefined;
  selectedBranches: string[] = []
  isFirstLoad: boolean = true;

  constructor(private _orderService: OrderService, private _browserNotification: BrowserNotificationService, private _soundService: SoundService) { }

  initializeTimer = (timerMinuteFraction: number = 0.5) => { this._soundService.initiateBranchSounds(); this._setTimer(timerMinuteFraction) }

  private _setTimer = (fractions: number) => (this.timer$ == undefined) && (this.timer$ = timer(0, this.MINUTE_IN_MILLISECONDS * fractions).subscribe(_ => {
    const orders = this._orderService.getOrderByBranch(this.selectedBranches);
    const scheduledOrders = this._orderService.getSchedualedOrder(this.selectedBranches)
    forkJoin([orders, scheduledOrders]).subscribe(
      (results: any) => {
        let [newOrders, newScheduledOrders] = results;
        this.newOrderChecker(newOrders)
        this.orders$.next(newOrders)
        this.newScheduledOrderChecker(newScheduledOrders)
        this.scheduledOrders$.next(newScheduledOrders)
        this.isFirstLoad = false;
      }
    )
  }))

  muteSound = () => this._soundService.stop();

  uninitializeTimer = () => {
    if (this.timer$) {
      this.timer$.unsubscribe();
      this.timer$ = undefined;
    }
  }
  pushToNewOrders = (order: any) => this.orders$.pipe(take(1)).subscribe(res => {this.orders$.next([order, ...res]) })
  newOrderEvent(branchid: string, type: 'order' | 'scheduled' = 'order') {
    this._soundService.play(branchid);
    this._browserNotification.orderNotification(`Notification!", "New ${type == 'order' ? 'orders' : 'scheduled orders'} check it up.`)
  }

  setSelectedBranches = (branches: string[]) => {
    this.muteSound();
    this.selectedBranches = branches;
    this._orderService.getOrderByBranch(this.selectedBranches).subscribe(orders => { this.orders$.next(orders); this.isFirstLoad = false; })
  }

  private newOrderChecker = (orders: any[]) => this.orders$.pipe(take(1)).pipe(map(oldOrders => oldOrders.map(i => i.id))).subscribe(oldIds => {
    let newOrders = orders.filter(newOrder => !oldIds.includes(newOrder.id));
    this._handleAutoAccept(newOrders.filter(o => o.isfirst && o.isautoaccept && o.delayinminutes != 0));
    if ((!this.isFirstLoad) && (newOrders.length > 0)) this.newOrderEvent(newOrders[0].branchid)
  })

  updateOrderStatus(order: any, status: any) {
    order.currentstatus = status.status
    order.statuscolor = status.color
    order.isfirst = false
    this.spliceOrder(order.id);
    this.pushToNewOrders(order);
  }

  private newScheduledOrderChecker = (scheduledOrders: any[]) => this.scheduledOrders$.pipe(take(1)).pipe(map(oldOrders => oldOrders.map(i => i.id))).subscribe(oldIds => {
    let newOrders = scheduledOrders.filter(newOrder => !oldIds.includes(newOrder.id));
    let lastBranchId: string = newOrders[0] && newOrders[0].branchid;
    (!this.isFirstLoad) && (newOrders.length > 0) && (this.newOrderEvent(lastBranchId, 'scheduled'))
  })

  private _handleAutoAccept = (orders: any[]) => orders.forEach(ota => setTimeout(() => this.handleUpdateOrderStatus(ota, true), ota.delayinminutes * this.MINUTE_IN_MILLISECONDS))

  spliceOrder = (id: string) => this.orders$.pipe(take(1)).subscribe(res => this.orders$.next(res.filter(order => order.id != id)));

  spliceScheduledOrder = (id: string) => this.scheduledOrders$.pipe(take(1)).subscribe(res => this.scheduledOrders$.next(res.filter(order => order.id != id)));

  handleUpdateOrderStatus = (order: any, automaticAction: boolean = false) => this._orderService.setOrderNextStatus(order.id, order.isfirst, !!order?.deliverytype, order?.deliverytype).subscribe(res => {
    automaticAction || this.muteSound();
    if (res.status) {
      (res.data.isready) ?
        this.spliceOrder(order.id) :
        this.updateOrderStatus(order, res.data)
    }
  })
}
