export const environment = {
  production:false,
  defultLanugage: 'en',
  userInfoUrl: 'https://uat-auth.ishbek.com/auth/realms/development/protocol/openid-connect/userinfo',
  authUrl: 'https://uat-auth.ishbek.com/auth/',
  authConfig: {
    issuer: 'https://uat-auth.ishbek.com/auth/realms/development',
    clientId: 'ishbek-website', // The "Auth Code + PKCE" client
    responseType: 'code',
    redirectUri: window.location.origin,
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    scope: 'openid profile email offline_access', // Ask offline_access to support refresh token refreshes
    useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
    sessionChecksEnabled: true,
    nonceStateSeparator: 'semicolon' // Real semicolon gets mangled by IdentityServer's URI encoding
    // silentRefreshTimeout: 3600000, // For faster testing
    // timeoutFactor: 1, // For faster testing
    // showDebugInformation: false, // Also requires enabling "Verbose" level in devtools
    // clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  },
  companyApi: 'https://uat-company.ishbek.com/api/',
  companyFileApi: 'https://uat-company.ishbek.com/files/',
  keycloakApiUrl: 'https://uat-keycloak.ishbek.com/api/',
  localizationApiUrl: 'https://uat-localization.ishbek.com/api/',
  filesurl: 'https://uat-files.ishbek.com/',
  printerurl: 'https://localhost:5001/api/',
  logOutUrl:'https://uat.ishbek.com'
};
