/* eslint-disable brace-style */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.login();
    // this._router.navigateByUrl('/should-login');
  }

  constructor(
    private _oauthService: OAuthService,
    private _router: Router,
  ) {
    // // Useful for debugging:
    // this._oauthService.events.subscribe((event: any) => {
    //   if (event instanceof OAuthErrorEvent) {
    //     console.error('OAuthErrorEvent Object:', event);
    //   } else {
    //    // console.warn('OAuthEvent Object:', event);
    //   }
    // });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      //console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this._oauthService.hasValidAccessToken());

      if (!this._oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });


    this._oauthService.events
      .subscribe(_ => {
        this.isAuthenticatedSubject$.next(this._oauthService.hasValidAccessToken());
      });

    this._oauthService.events
      .pipe(filter((e: any) => ['token_received'].includes(e.type)))
      .subscribe((e: any) => this._oauthService.loadUserProfile());

    this._oauthService.events
      .pipe(filter((e: any) => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe((e: any) => this.navigateToLoginPage());

    this._oauthService.setupAutomaticSilentRefresh();
  }

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      //console.log('Encountered hash fragment, plotting as table...');
      console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return this._oauthService.loadDiscoveryDocument()

      // For demo purposes, we pretend the previous call was very slow
      //.then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1000)))

      // 1. HASH LOGIN:
      // Try to log in via hash fragment after redirect back
      // from IdServer from initImplicitFlow:
      .then(() => this._oauthService.tryLogin())

      .then(() => {
        if (this._oauthService.hasValidAccessToken()) {
          this._saveAccessType()
          return Promise.resolve();
        }

        // 2. SILENT LOGIN:
        // Try to log in via a refresh because then we can prevent
        // needing to redirect the user:
        return this._oauthService.silentRefresh()
          .then(() => Promise.resolve())
          .catch((result: any) => {
            // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
            // Only the ones where it's reasonably sure that sending the
            // user to the IdServer will help.
            const errorResponsesRequiringUserInteraction = [
              'interaction_required',
              'login_required',
              'account_selection_required',
              'consent_required',
            ];

            if (result
              && result.reason
              && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

              // 3. ASK FOR LOGIN:
              // At this point we know for sure that we have to ask the
              // user to log in, so we redirect them to the IdServer to
              // enter credentials.
              //
              // Enable this to ALWAYS force a user to login.
              // this.login();
              //
              // Instead, we'll now do this:
              //console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
              return Promise.resolve();
            }

            // We can't handle the truth, just pass on the problem to the
            // next handler.
            return Promise.reject(result);
          });
      })

      .then(() => {
        this.isDoneLoadingSubject$.next(true);
        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this._oauthService.state && this._oauthService.state !== 'undefined' && this._oauthService.state !== 'null') {
          let stateUrl = this._oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          //console.log(`There was state of ${this._oauthService.state}, so we are sending you to: ${stateUrl}`);
          this._router.navigateByUrl(stateUrl);
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  // Note: before version 9.1.0 of the library you needed to
  // call encodeURIComponent on the argument to the method.
  public login(targetUrl?: string) { this._oauthService.initLoginFlow(targetUrl || this._router.url); }
  public logout() { this._oauthService.logOut(); localStorage.clear(); window.location.href = environment.logOutUrl }
  public refresh() { this._oauthService.silentRefresh(); }
  public hasValidToken() { return this._oauthService.hasValidAccessToken(); }
  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() { return this._oauthService.getAccessToken(); }
  public get refreshToken() { return this._oauthService.getRefreshToken(); }
  public get identityClaims(): any { return this._oauthService.getIdentityClaims(); }
  public get idToken() { return this._oauthService.getIdToken(); }
  public get logoutUrl() { return this._oauthService.logoutUrl; }
  public loadUserProfile() { return this._oauthService.loadUserProfile(); }
  public silentRefresh() { return this._oauthService.silentRefresh(); }
  private _saveAccessType() { localStorage.setItem('accesstype', jwt_decode<any>(this.accessToken)?.accesstype) }
  public getCompanyIdForAdmins = () => jwt_decode<any>(this.accessToken)?.company_Id;
}
