import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { pluck, share, shareReplay, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  // createCompanyPayment(arg0: { companyid: any; paymentOption: any; currencyOption: any; }) {
  //   throw new Error('Method not implemented.');
  // }

  constructor(
    private _httpClient: HttpClient) { }
  private allowed_extensions = ['png', 'jpg', 'bmp', 'jpeg', 'svg'];
  apiUrl = 'Company/';
  apiUrl2 = 'Companyinfo/';

  getCompanyProfile = () => this._httpClient.get(`${environment.companyApi}${this.apiUrl2 + 'GetProfileInfo'}`).pipe(pluck('data'));
  updateCompanyProfile = (info: any) => this._httpClient.put(`${environment.companyApi}${'Companyinfo/UpdateCompanyProfile'}`, info)

  getCompanyChannels(companyid?:string){
    return this._httpClient.get(`${environment.companyApi}companyinfo/getcompanychannels${companyid == undefined ? '' : '?companyId='+companyid}`).pipe(pluck('data'),shareReplay(5));
  }

  getAllCompanyChannels(){
    return this._httpClient.get(`${environment.companyApi}Ordersource/GetAllOrderSources`).pipe(pluck('data'));
  }


  UpdateCompanyChannelAvtivation(data: any) {
    return this._httpClient.put(`${environment.companyApi}companyinfo/UpdateCompanyChannelAvtivation`, data).pipe(pluck('status'));

  }
  getall(): Observable<any[]> {
    return this._httpClient
      .get(
        `${environment.companyApi}${this.apiUrl + 'GetAll'
        }`
      )
      .pipe(take(1), pluck('data'));
  }
  getById(): Observable<any> {
    return this._httpClient
      .get(
        `${environment.companyApi}${this.apiUrl + 'Get'
        }`
      )
      .pipe(shareReplay(1), pluck('data'));
  }
  getInfoById(): Observable<any[]> {
    return this._httpClient
      .get(
        `${environment.companyApi}${'companyinfo/y' + 'Get'
        }`
      )
      .pipe(take(1), pluck('data'));
  }

  uploadCompanyLogo(logo: File): Observable<any> {
    let file: File = logo;
    const valid = this._checkValidFiles(file)
    if (valid) {
      const formData = new FormData();
      formData.append('file', file)
      return this._httpClient.post(`${environment.companyApi}${this.apiUrl + 'uploadLogo'}`, formData).pipe(take(1));
    }
    return of({});
  }
  private _checkValidFiles(file: File): boolean {
    let validity = false
    const ext = file.name.split('.')[file.name.split('.').length - 1];
    if (this.allowed_extensions.lastIndexOf(ext) != -1)
      validity = true
    return validity;
  }



}
