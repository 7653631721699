import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyuserService {

  constructor(private _httpClient: HttpClient) { }
  apiUrl = 'companyuser/';

  secondapiUrl = 'Registration/';
  CreateCompanyUser(user: any): Observable<any> { return this._httpClient.post(`${environment.companyApi}${this.apiUrl + 'create'}`, user).pipe(take(1)) };

  GetUserSettingView(): Observable<any> { return this._httpClient.get(`${environment.companyApi}${this.apiUrl + 'GetCompanyUsers'}`).pipe(take(1), pluck('data')) };

  GetIshbekCompanyUser(): Observable<any> { return this._httpClient.get(`${environment.companyApi}${this.apiUrl + 'GetIshbekCompanyUser'}`).pipe(take(1), pluck('data')) };

  

  CreateCashier(user: any): Observable<any> {
    return this._httpClient.post(`${environment.companyApi}${this.secondapiUrl + 'Register'}`, user).pipe(take(1))
  };

  createCompanyAdmin(user:any){
    return this._httpClient.post(`${environment.companyApi}${this.secondapiUrl + 'RegisterAdmin'}`, user).pipe(take(1))
  }

  createDispatcher(user:any){
    return this._httpClient.post(`${environment.companyApi}${this.secondapiUrl + 'RegisterDispatcher'}`, user).pipe(take(1))
  }

  Createagent(user: any): Observable<any> { return this._httpClient.post(`${environment.companyApi}${this.secondapiUrl + 'RegisterAgent'}`, user).pipe(take(1)) };


  EnabledUser(data: any) {
    return this._httpClient.put(`${environment.companyApi}${'Registration/EnabledUser'}`, data).pipe(take(1));
  }

}
