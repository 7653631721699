// import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
// import { NavigationEnd, Router } from '@angular/router';
// import { TranslateService } from '@ngx-translate/core';
// import { locale as ENGLISHLANGUAGE } from './i18n/en';
// import { locale as ARABICLANGUAGE } from './i18n/ar';
// import { LanguageLoaderService, UrlService } from './shared/services';
import { Observable } from 'rxjs';
import { AuthService } from './shared/core/';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'app';
  // previousUrl!: string;
  // currentUrl!: string;
  isAuthenticated$!: Observable<boolean>;
  isDoneLoading$!: Observable<boolean>;
  canActivateProtectedRoutes$!: Observable<boolean>;
  constructor(
    // private _urlSerivce: UrlService,
    // private _rotuer: Router,
    // private _languageLoaderServices: LanguageLoaderService,
    // private _translateService: TranslateService,
    // @Inject(DOCUMENT) private _document: Document,
    private _authService: AuthService
  ) {
    this.isAuthenticated$ = this._authService.isAuthenticated$;
    this.isDoneLoading$ = this._authService.isDoneLoading$;
    this.canActivateProtectedRoutes$ = this._authService.canActivateProtectedRoutes$;
    this._authService.runInitialLoginSequence()
  }
  ngOnInit(): void {
    console.log('v2.0.0');
    // this._rotuer.events.subscribe((val) => {
    //   if (val instanceof NavigationEnd) {
    //     this.previousUrl = this.currentUrl;
    //     this.currentUrl = val.url;
    //     this._urlSerivce.setPreviousUrl(this.previousUrl);
    //   }
    // });

    // this._translateService.addLangs(['en', 'ar']);
    // let currentLangauge: string = 'en';
    // let selectedLanguage = localStorage.getItem('language');
    // if (selectedLanguage) {
    //   currentLangauge = selectedLanguage;
    //   if (currentLangauge === 'ar') {
    //     this._document.body.classList.add('rtl');
    //   }
    // }
    // this._translateService.setDefaultLang(currentLangauge);
    // this._translateService.use(currentLangauge);
    // this._languageLoaderServices.loadTranslations(ENGLISHLANGUAGE, ARABICLANGUAGE);
  }
}
