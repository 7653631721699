import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, pluck, take } from 'rxjs/operators';
import { Product } from '../api-modal';
import { Benefeciary } from '../layout/internal/company/availability/item-availability/item-availability.component';
import { Category } from '../shared/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  apiUrl = 'product/';
  selectedlanguage = 'en'
  constructor(
    private _httpClient: HttpClient) {
      this.selectedlanguage= localStorage.getItem('language')||'en'
    }
  private allowed_extensions = ['png', 'jpg', 'bmp', 'jpeg', 'svg'];

  createProduct = (product: Product) => this._httpClient.post(`${environment.companyApi}${this.apiUrl + 'create'}`, product);//.pipe(catchError(err => of(err)))
  // return this._httpClient.post(`${this._config.api}${this.apiUrl + 'create'}`, product);

  getProductPrice = (id: string): Observable<string> => this._httpClient.get<string>(environment.companyApi + this.apiUrl + "getprice?id=" + id).pipe(take(1), pluck('data'));
  // return this._httpClient.get<string>(this._config.api + this.apiUrl + "getprice?id=" + id).pipe(pluck('data'),take(1));

  getProductOFCategoryCompany = (): Observable<Product[]> => this._httpClient.get<Product[]>(environment.companyApi + this.apiUrl + "getproductcomapny").pipe(take(1), pluck('data'));
  GetCategoryOFCompany = (): Observable<Category[]> => this._httpClient.get<Category[]>(environment.companyApi + this.apiUrl + "getcategoriescompany").pipe(take(1), pluck('data'));

  getProductCompanyWithoutValidation = (): Observable<Product[]> => this._httpClient.get<Product[]>(environment.companyApi + this.apiUrl + "GetProductCompanyWithoutValidation").pipe(take(1), pluck('data'));

  getProductOFCategoryCompanyDTO = (): Observable<Product[]> => this._httpClient.get<Product[]>(environment.companyApi + this.apiUrl + "GetProductComapnyDTO").pipe(take(1), pluck('data'));

  getUnAvailableOfCategoryCompany = ():Observable<Product[]> => this._httpClient.get<Product[]>(environment.companyApi+ this.apiUrl+"GetUnavailableProductComapny").pipe(take(1),pluck('data'));

  getProductById = (id: string): Observable<Product[]> => this._httpClient.get<Product[]>(environment.companyApi + this.apiUrl + "get?id=" + id).pipe(take(1), pluck('data'));

  getProductsTagsById = (productid:string) => this._httpClient.get<any>(environment.companyApi + this.apiUrl + "GetProductTagsById?productid="+productid).pipe(take(1), pluck('data'));

  createProductTags=(productid:string,tags:any)=>this._httpClient.post<any>(environment.companyApi + this.apiUrl +"CreateProductTags",{productid,...tags}).pipe(take(1))

  getProductsByIds(id: any[]){
  let query = '?';
  id.forEach(i => { query += 'productids=' + i + '&' })
   return this._httpClient.get<Product[]>(environment.companyApi + this.apiUrl + "GetByIds"  + query).pipe(take(1), pluck('data'));
  }

  getProductsByProductsIds(productids:any[]){
    return this._httpClient.get<any>(environment.companyApi + this.apiUrl + "GetByIds?Productids="+productids ).pipe(take(1), pluck('data'));
  }

  getProductsByCategory = (id: string,menuid:string): Observable<any[]> => this._httpClient.get<any>(environment.companyApi + this.apiUrl + "getbycategory?id=" + id+'&menuid='+menuid).pipe(take(1),pluck('data')).pipe(map((res: any[]) => {
    let options: any[] = []
    res.forEach(product => {
      let option = {
        ...product,
        id: product.id,
        name: JSON.parse(product.name)[this.selectedlanguage]||JSON.parse(product.name).en,
        image: environment.filesurl + product.imagepath
      }
      options.push(option);
    });
    return options
  }));


  getProductsByCategorys = (id: string): Observable<any[]> => this._httpClient.get<any>(environment.companyApi + this.apiUrl + "getbycategorys?id=" + id).pipe(take(1),pluck('data')).pipe(map((res: any[]) => {
    let options: any[] = []
    res.forEach(product => {
      let option = {
        ...product,
        id: product.id,
        name: JSON.parse(product.name)[this.selectedlanguage]||JSON.parse(product.name),
        image: environment.filesurl + product.imagepath
      }
      options.push(option);
    });
    return options
  }));
  getProductAvailableProductsByCategoryid = (categoryId: string,branchId:string): Observable<any> => this._httpClient.get<any>(environment.companyApi + this.apiUrl + 'GetbyCategoryAvailableFromMenu?CategoryId=' + categoryId+'&BranchId='+branchId).pipe(pluck('data'),take(1), map((items: any[]) => { items.forEach(item => { item.name = JSON.parse(item.name); item.image = item.image; item.shortdescription = JSON.parse(item.shortdescription!); item.price = JSON.parse(item.price!); item.description = JSON.parse(item.description!); }); return items }));


  getproductbyarraycategory(id: string[]): Observable<any[]>  {
    let query = '?';
    id.forEach(i => { query += 'id=' + i + '&' })
    return this._httpClient.get<any[]>(`${environment.companyApi}${'product/GetProductByArrayCategory' + query}`).pipe(take(1),pluck('data'));
  }



  uploadProductImage(logo: File): Observable<any> {

    let file: File = logo;
    const valid = this._checkValidFiles(file)
    if (valid) {
      const formData = new FormData();
      formData.append('file', file)
      return this._httpClient.post(`${environment.companyApi}${this.apiUrl + 'uploadProductImage'}`, formData).pipe(take(1));
    }
    return of({});
  }
  private _checkValidFiles(file: File): boolean {

    let validity = false
    const ext = file.name.split('.')[file.name.split('.').length - 1];
    if (this.allowed_extensions.lastIndexOf(ext) != -1)
      validity = true
    return validity;
  }
  deleteProduct = (productId: any) => this._httpClient.delete(environment.companyApi + this.apiUrl + "Delete?id=" + productId).pipe(take(1))

  getBenefeciaries = (): Observable<Benefeciary[]> => this._httpClient.get<Benefeciary[]>(`${environment.companyApi}${'Benefeciary/GetAll'}`).pipe(take(1), pluck('data'));

  addAttributeToProduct(productID: string, attribute: any) {
    if (productID) {
      let selectedProductAttribute = { Productid: productID, Attribute: attribute }
      return this._httpClient.post(`${environment.companyApi}${'productselectedattribute/create'}`, selectedProductAttribute).pipe(take(1));
    }
    return null
  }
  updateProductPrice = (productPrice: any) => this._httpClient.put(`${environment.companyApi}${this.apiUrl + 'updatePrice'}`, productPrice).pipe(take(1));

  updateProduct = (product: any) => this._httpClient.put(`${environment.companyApi}${this.apiUrl + 'update'}`, product).pipe(take(1));
}
